import React from "react"
import { PageProps, graphql } from "gatsby"
import Helmet from "react-helmet"

import { SEO } from "src/components/seo"
import { VideoLoopResponsive } from "src/components/video-responsive"
import { ImgFluid } from "src/components/img-fluid"

import {
  HeadlineAndParagraph,
  HeaderHero,
  CaseStudyCapabilities,
} from "src/components/layouts"

import {
  Container,
  Block,
  Grid,
  Col,
  HorizontalRuler,
  Headline,
  Paragraph,
  HeadlineHero,
} from "src/components/primitives"

import { useLightTheme } from "src/context/theme"

type DataProps = {
  project: {
    seo: {
      title: string
      description: string
      image: {
        publicURL: string
      }
    }
    copy: {
      title: string
      subtitle: string
      intro: string
      concepts: string
      research_title: string
      research_copy_1: string
      research_copy_2: string
      flow_title: string
      flow_copy: string
      analytics_title: string
      analytics_copy: string
      expertise: string
    }
    images: {
      hero: any
      journey_full: any
      grid_1_1: any
      grid_1_2: any
      grid_1_3: any
      grid_1_4: any
      grid_1_5: any
      grid_1_6: any
      flow_full: any
      grid_2_1: any
      grid_3_1: any
      grid_3_2: any
      grid_3_3: any
      grid_3_4: any
      analytics_full: any
    }
    videos: {
      hero: any
      journey_full: any
      flow_full: any
      analytics_full: any
    }
  }
}

const CaseStudy: React.FC<PageProps<DataProps>> = ({ data }) => {
  const { seo, copy, images, videos } = data.project

  useLightTheme()

  return (
    <article aria-label={copy.title}>
      <Helmet
        bodyAttributes={{
          class: "theme-light",
        }}
      />
      <SEO
        title={seo.title}
        description={seo.description}
        image={seo.image.publicURL}
      />
      <Container>
        <HeaderHero>
          <HeadlineHero>
            {copy.title}
            <span>{copy.subtitle}</span>
          </HeadlineHero>
        </HeaderHero>
      </Container>

      <Container bleed>
        <Block>
          <VideoLoopResponsive 
            video={videos.hero} 
            image={images.hero} 
            />
        </Block>
      </Container>

      <Container>
        <Paragraph>{copy.intro}</Paragraph>
      </Container>

      <Container>
        <HorizontalRuler />
      </Container>

      <Container bleed>
        <Block>
          <VideoLoopResponsive
            video={videos.journey_full}
            image={images.journey_full}
          />
        </Block>
      </Container>

      <Container>
        <HeadlineAndParagraph>
          <Headline>{copy.research_title}</Headline>
          <Paragraph>{copy.research_copy_1}</Paragraph>
        </HeadlineAndParagraph>
      </Container>

      <Container>
        <Block>
          <Grid gap columns={{ bp0: "$1", bp1: "$2" }}>
            <Grid gap>
              <Col>
                <ImgFluid image={images.grid_1_1} />
              </Col>
              <Col>
                <ImgFluid image={images.grid_1_2} />
              </Col>
              <Col>
                <ImgFluid image={images.grid_1_3} />
              </Col>
            </Grid>
            <Grid gap>
              <Col>
                <ImgFluid image={images.grid_1_4} />
              </Col>
              <Col>
                <ImgFluid image={images.grid_1_5} />
              </Col>
              <Col>
                <ImgFluid image={images.grid_1_6} />
              </Col>
            </Grid>
          </Grid>
        </Block>
      </Container>

      <Container>
        <Grid gap columns={{ bp4: "$12" }}>
          <Col start={{ bp4: "$6" }} end={{ bp4: "$13" }}>
            <Paragraph>{copy.research_copy_2}</Paragraph>
          </Col>
        </Grid>
      </Container>

      <Container>
        <HorizontalRuler />
      </Container>

      <Container>
        <HeadlineAndParagraph>
          <Headline>{copy.flow_title}</Headline>
        </HeadlineAndParagraph>
      </Container>

      <Container bleed>
        <Block>
          <VideoLoopResponsive
            video={videos.flow_full}
            image={images.flow_full}
          />
        </Block>
      </Container>

      <Container>
        <Paragraph>{copy.flow_copy}</Paragraph>
      </Container>

      <Container>
        <Block>
          <ImgFluid image={images.grid_2_1} />
        </Block>
      </Container>

      <Container>
        <HorizontalRuler />
      </Container>

      <Container>
        <HeadlineAndParagraph>
          <Headline>{copy.analytics_title}</Headline>
          <Paragraph>{copy.analytics_copy}</Paragraph>
        </HeadlineAndParagraph>
      </Container>

      <Container>
        <Block>
          <Grid gap columns={{ bp0: "$1", bp1: "$2" }}>
            <Col>
              <ImgFluid image={images.grid_3_1} />
            </Col>
            <Col>
              <ImgFluid image={images.grid_3_2} />
            </Col>
            <Col>
              <ImgFluid image={images.grid_3_3} />
            </Col>
            <Col>
              <ImgFluid image={images.grid_3_4} />
            </Col>
            <Col
              start={{ bp0: "$1", bp1: "$1" }}
              end={{ bp0: "$2", bp1: "$3" }}
            >
              <VideoLoopResponsive
                video={videos.analytics_full}
                image={images.analytics_full}
              />
            </Col>
          </Grid>
        </Block>
      </Container>

      <Container>
        <CaseStudyCapabilities>{copy.expertise}</CaseStudyCapabilities>
      </Container>
    </article>
  )
}

export default CaseStudy

export const pageQuery = graphql`
  query {
    project: workJson(id: { eq: "delta" }) {
      id
      seo {
        title
        description
        image {
          publicURL
        }
      }
      copy {
        title
        subtitle
        intro
        concepts
        research_title
        research_copy_1
        research_copy_2
        flow_title
        flow_copy
        analytics_title
        analytics_copy
        expertise
      }
      images {
        hero {
          alt
          src {
            childImageSharp {
              ...HeroImageFragment
            }
          }
        }
        journey_full {
          alt
          src {
            childImageSharp {
              ...HeroImageFragment
            }
          }
        }
        grid_1_1 {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_1_2 {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_1_3 {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_1_4 {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_1_5 {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_1_6 {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        flow_full {
          alt
          src {
            childImageSharp {
              ...HeroImageFragment
            }
          }
        }
        grid_2_1 {
          alt
          src {
            childImageSharp {
              ...HeroImageFragment
            }
          }
        }
        grid_3_1 {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_3_2 {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_3_3 {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_3_4 {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        analytics_full {
          alt
          src {
            childImageSharp {
              ...HeroImageFragment
            }
          }
        }
      }
      videos {
        hero {
          src {
            src_large
            src_medium
            src_small
          }
        }
        journey_full {
          src {
            src_large
            src_medium
            src_small
          }
        }
        flow_full {
          src {
            src_large
            src_medium
            src_small
          }
        }
        analytics_full {
          src {
            src_large
            src_medium
            src_small
          }
        }
      }
    }
  }
`
